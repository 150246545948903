import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { connect, useDispatch, useSelector } from "react-redux";
import MarkdownIt from "markdown-it";

import { CommonAction } from "../../../../store/common/thunk";
import EditProperty from "./EditPopup";
import COMMON_ACTIONS from "../../../../store/common/actions";
import { EDIT_PROPERTY_TYPE, MESSAGES, USER_TYPE } from "../../../../constants";
import { BedroomWithBathroom, BedroomWithoutBathroom, LocationIcon } from "../../../../assets/images";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import styles from "./style.module.css";
import viewStyles from "../../styles/view.module.css";
import pen from "../../../../assets/images/pen.png"

const ViewProperty = ({ loader, specificProperty, getRecommendedProperties }) => {


    const settings = {
        dots: true,
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },

            },
        ],
    };
    var mbslides = {

        dots: true,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    infinite: true,
                    speed: 1000,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    autoplay: true,
                    autoplaySpeed: 1400,
                }
            },

        ]
    };



    const { id } = useParams();
    const dispatch = useDispatch();
    const windowWidth = useWindowWidth();
    const mdParser = new MarkdownIt({ html: true, linkify: true, typographer: true });

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [editProperty, setEditProperty] = useState({ show: false, type: "" });
    const [locationDetails, setLocationDetails] = useState({ area: "", building: "", city: "" });
    const [propertyFeatures, setPropertyFeatures] = useState({ bedrooms: [], nonAttachedBathrooms: [], bedIcons: [] });
    const [propertyItems, setPropertyItems] = useState({ bedrooms: 0, bathrooms: 0, capacity: 0, beds: 0 });
    const [recommendedProperties, setRecommendedProperties] = useState({ properties: [], count: 0 });

    const { property, propertyLoader, user } = useSelector((state) => state.common);

    const handlePropertyOptionClick = (type) => {
        if (user.userType !== USER_TYPE.VENDOR) return;

        setEditProperty({ show: true, type });
    };

    useEffect(() => {
        const query = { id };

        specificProperty(query, loader);

        const recommendedPropertiesQuery = {
            propertyId: id,
            offset: 0,
            limit: 6,
        };

        getRecommendedProperties(recommendedPropertiesQuery, loader).then(res => {
            setRecommendedProperties({ properties: res.properties, count: res.count });
        });
    }, []);

    useEffect(() => {
        if (Object.keys(property).length) {
            const selectedCity = property.cityData.find(item => item.isSelected);
            const selectedArea = selectedCity.areaData.find(item => item.isSelected);
            const selectedBuilding = selectedArea.buildingData.find(item => item.isSelected);

            let bedrooms = [];
            let bathroomCount = 0;
            let bedCount = 0;

            for (const item of property.bedRoomData) {
                let obj = item;
                if (item.bathRoom) {
                    obj.bathRoomData = property.bathRooms.find(it => it.bedRoomNumber === item.bedRoomNumber)
                    bathroomCount += 1;
                }
                else obj.bathRoomData = null;

                let icons = item.bedData.map(item => ({ icon: item.bedIcon }));

                bedCount += item.bedData.length;
                obj.bedIcons = icons;

                bedrooms.push(obj);
            }

            const nonAttachedBathrooms = property.bathRooms.filter(item => !item.bedRoomNumber);

            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "selectedImage", value: property.images });
            dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: property.images });

            setPropertyItems({ ...propertyItems, capacity: property.personCapacity, bedrooms: bedrooms.length, bathrooms: bathroomCount + nonAttachedBathrooms.length, beds: bedCount });

            setPropertyFeatures({ bedrooms: bedrooms, nonAttachedBathrooms });
            setLocationDetails({ city: selectedCity.name, building: selectedBuilding.name, area: selectedArea.name });
        }
    }, [property]);

    const closeEditPropertyModal = () => setEditProperty({ show: false, type: "" });

    if (propertyLoader) {
        return <div className="d-flex justify-content-center align-items-center flex-column loading-text">
            <h5>{MESSAGES.LOADING}</h5>
        </div>
    }
    else if (!Object.keys(property).length) {
        return (
            <div className={`d-flex justify-content-center align-items-center flex-column ${viewStyles["no-data"]}`}>
                <i className="fa fa-exclamation-triangle" />
                <h4>{MESSAGES.PROPERTY.NOT_FOUND}</h4>
            </div>
        )
    }

    return (
        <div className="dasboad-property-detail">
            <div className={viewStyles["product-slider-main"]}>
                <div className="main-sider">
                    <div className={viewStyles["side-left"]}>
                        <Slider  {...mbslides}
                            asNavFor={nav2}
                            ref={(slider1) => setNav1(slider1)}
                            dots={true}
                        >
                            {property.images.map((slideimg, key) => (
                                <div key={key}>
                                    <img src={slideimg} alt="property" />
                                </div>
                            ))}
                        </Slider>
                        <div className="mobile-edit">
                        {
                            user.userType === USER_TYPE.VENDOR ?
                                <div onClick={() => setEditProperty({ show: true, type: EDIT_PROPERTY_TYPE.IMAGES })} className={viewStyles["show-all"]}>
                                    <p><img src={pen} alt="" /></p>
                                </div> : null
                        }
                        </div>
                    </div>

                    <div className={viewStyles["side-sider"]}>
                        <div className="mobile-slide-custom">
                            <Slider
                                asNavFor={nav1}
                                ref={(slider2) => setNav2(slider2)}
                                slidesToShow={3}
                                swipeToSlide={true}
                                focusOnSelect={true}
                            >
                                <div>
                                    <img src={property.images && property.images[0]} alt="propertyView" />
                                </div>
                                <div>
                                    <img src={property.images && property.images[1]} alt="propertyView" />
                                </div>
                                <div>
                                    <img src={property.images && property.images[2]} alt="propertyView" />
                                </div>
                            </Slider>
                        </div>
                        {
                            user.userType === USER_TYPE.VENDOR ?
                                <div onClick={() => setEditProperty({ show: true, type: EDIT_PROPERTY_TYPE.IMAGES })} className={viewStyles["show-all"]}>
                                    <p>Edit</p>
                                </div> : null
                        }
                    </div>
                    {/* <div className="mobSlider">
                    <Slider {...mobslider}>
                         
                         {property.images.map((slideimg, key) => (
                                <div key={key}>
                                    <img src={slideimg} alt="property" />
                                </div>
                            ))}
                       
                            </Slider>
                    </div> */}
                </div>
                <div>
                    <div
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.NAME)}
                        className={`mobie-detail
                             ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                    >
                        <h4>{property.name}{" "} ({property.unitNumber}) <img src={pen} alt="" /></h4>
                    </div>
                    <div className="mobie-detail">
                        <h6>Location</h6>
                        <h5
                            className={`loc  d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.LOCATION)}
                        >
                            <img src={LocationIcon} alt="location" />
                            {locationDetails.building}, {locationDetails.area}, {locationDetails.city}
                            <img src={pen} alt="" />
                        </h5>

                    </div>
                    <div className="mobie-detail">
                        <div className={`${viewStyles["pro-featu"]} cursor-pointer`} onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)}>
                            <h6>Property Feature <img src={pen} alt="" /></h6>
                            <ul>
                                <li>{property.personCapacity} Guest</li>
                                <li>{property.isStudio ? "Studio" : property.bedrooms +" Bedroom"}</li>
                                <li>{property.bathroomsCount} Bathroom</li>
                                <li>{property?.beds} Beds</li>
                            </ul>
                        </div>
                    </div>
                    <div className="mobie-detail">
                        <div
                            onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.DESCRIPTION)}
                            className={` d-flex ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                        >
                            <h6>Description <img src={pen} alt="" /></h6>
                        </div>
                        <div className="cursor-pointer" onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.DESCRIPTION)}>
                            <div dangerouslySetInnerHTML={{ __html: mdParser.render(property.description) }} />
                        </div>
                    </div>
                    <div className="mobie-detail">
                        <div
                            className={`description d-flex mt-4 mb-2 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)}
                        >
                            <h6>Property Feature <img src={pen} alt="" /></h6>
                        </div>

                        <div className="main-room-slider cursor-pointer" onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.FEATURES)}>
                            <Slider
                                slidesToShow={windowWidth < 766 ? 1 : propertyFeatures.bedrooms.length < 3 ? propertyFeatures.bedrooms.length : 3}
                                slidesToScroll={windowWidth < 766 ? 1 : propertyFeatures.bedrooms.length < 3 ? propertyFeatures.bedrooms.length : 3}
                                dots={true}
                            >
                                {propertyFeatures.bedrooms.map((bedroom, index) => (
                                    <div key={index} className={`${styles.propertyFeature} ${propertyFeatures.bedrooms.length === 1 ? "w-25" : ""}`}>

                                        <p>Room {index + 1}</p>
                                        <ul>
                                            {bedroom.bedData.map((it, innerInd) => (
                                                <li key={innerInd}>
                                                    <div>
                                                        <img
                                                            src={bedroom.bedIcons[innerInd].icon}
                                                            className={styles.bedIcon}
                                                            alt="double_bed"
                                                        />
                                                        {it.bedName} ({it.numberOfBeds}) {bedroom.bathRoomData ? "with Bathroom" : ""}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className="mobie-detail">
                        <div>
                            {propertyFeatures.nonAttachedBathrooms.length > 0 ? <p>{propertyFeatures.nonAttachedBathrooms.length} Non Attached Bathroom</p> : null}
                        </div>


                        {
                            property.specialAmmenities.length > 0 &&
                            <Fragment>
                                <div className='description d-flex mb-2'>
                                    <h6 className={styles["special-amnitiy"]}>Special Features <img src={pen} alt="" /></h6>
                                </div>

                                <ul className={`${styles.amnites} ${styles["aminity-wh-img"]} cursor-pointer`} onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)}>
                                    {property.specialAmmenities && property.specialAmmenities.map((item, key) => (
                                        <li key={key} >
                                            <img src={item.icon} alt="" />
                                            {item.title}
                                        </li>
                                    ))}
                                </ul>
                            </Fragment>
                        }
                    </div>
                    <div className="mobie-detail">
                        <div
                            className={`description d-flex mt-4 mb-2 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)}
                        >
                            <h6>Ammenities <img src={pen} alt="" /></h6>
                        </div>

                        <ul className={`${styles.amnites} ${styles["aminity-wh-img"]} cursor-pointer`} onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.AMMENITIES)}>
                            {property.commonAmenities && property.commonAmenities.map((item, key) => (
                                <li key={key}>
                                    <img src={item.icon} alt="" />
                                    {item.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="mobie-detail">
                        <div
                            className={`description d-flex mb-3 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                            onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.OTHER_ROOM)}
                        >
                            <h6>Other Rooms <img src={pen} alt="" /></h6>
                        </div>

                        <ul className={`${styles.amnites} ${styles["aminity-wh-img"]} ${styles["other-room"]} cursor-pointer`} onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.OTHER_ROOM)}>
                            {
                                property.otherRoom.driverRoom ?
                                    <li>
                                        <img alt="driverRoom" className={styles.room} src={property.otherRoom.driverRoomBathRoom ? BedroomWithBathroom : BedroomWithoutBathroom} />
                                        Driver Room {property.otherRoom.driverRoomBathRoom ? " with attached bathroom" : ""}</li>
                                    : <li>No Driver Room</li>
                            }

                            {
                                property.otherRoom.maidRoom ?
                                    <li>
                                        <img alt="maidRoom" className={styles.room} src={property.otherRoom.maidRoomBathRoom ? BedroomWithBathroom : BedroomWithoutBathroom} />
                                        Maid Room {property.otherRoom.maidRoomBathRoom ? " with attached bathroom" : ""}</li>
                                    : <li>No Maid Room</li>
                            }
                        </ul>
                    </div>
                    <div className='description d-flex mt-4 mb-2'>
                        <h4>View on map</h4>
                    </div>
                    <div id="map-show" className={viewStyles["map"]}>
                        <iframe
                            title="Car Location"
                            height="500px"
                            width="100%"
                            src={`https://maps.google.com/maps?q=${property && property.latitude},${property && property.longitude}&h1=es;&output=embed`}
                        >
                        </iframe>
                    </div>

                    <div
                        className={`description d-flex mt-4 mb-2 ${user.userType === USER_TYPE.VENDOR ? "cursor-pointer" : ""}`}
                        onClick={() => handlePropertyOptionClick(EDIT_PROPERTY_TYPE.RECOMMENDED)}
                    >
                        <h4>You may also like</h4>
                    </div>
                    <Slider {...settings}>
                        {
                            recommendedProperties.properties.map((item, key) => (
                                <div key={key} className={styles.recommendedProperty}>
                                    <img src={item.image} alt="property" />
                                    {item.positionNumber ? <p className={styles.count}>{item.positionNumber}</p> : null}
                                    <div className={styles["slide-name"]}>
                                        <h4>{item.name}</h4>
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>

            <EditProperty
                {...editProperty}
                property={property}
                closeEditPropertyModal={closeEditPropertyModal}
                loader={loader}
                recommendedProperties={recommendedProperties.properties}
                propertyCount={recommendedProperties.count}
            />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    specificProperty: (query, loader) => dispatch(CommonAction.specificProperty(query, loader)),
    getRecommendedProperties: (query, loader) => dispatch(CommonAction.recommendedProperties(query, loader)),
});

export default connect(null, mapDispatchToProps)(ViewProperty);
