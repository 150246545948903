import React, { Fragment, useEffect, useState, useCallback, useRef } from "react";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  IMAGE_FILE_TYPES,
  MAXIMUM_FILE_SIZE_LIMIT,
  MAXIMUM_FILE_UPLOAD_LIMIT,
  MESSAGES,
  MINIMUM_FILE_UPLOAD_LIMIT,
  FILE_UPLOAD_TYPE,
} from "../../constants";
import COMMON_ACTIONS from "../../store/common/actions";
import { processImage, uploadImage } from "../../utils/imageHelper";
import dropImageStyles from "./style.module.css";

const styles = {
  preview: {
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%" },
  delete: {
    cursor: "pointer",
    color: "white",
    border: "none",
  },
};

const AppPropDropImage = (props) => {

  const dispatch = useDispatch();
  const { selectedImage, images } = useSelector((state) => state.common);
  const { selectedLocalmage, localImages } = useSelector((state) => state.common);

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(
    selectedLocalmage?.map((item) => (item ? false : true)) || []
  );
  const [loadingImage, setLoadingImage] = useState(0)
  const [forceRender, setForceRender] = useState(0); // Create a state to force updates

  const imageUrlsRef = useRef({});
  console.log("selectedLocalmage-----", loading, localImages)

  // Update loading state when selectedLocalmage changes
  useEffect(() => {
    console.log("selectedLocalmage", selectedLocalmage, loading)
    if (selectedLocalmage) {
      setLoading(selectedLocalmage.map((item) => (item ? false : false)));
    }
  }, [selectedLocalmage]);
  useEffect(() => {
    return () => {
      // Revoke all object URLs when component unmounts
      Object.values(imageUrlsRef.current).forEach((url) => URL.revokeObjectURL(url));
    };
  }, []);

  // Memoized function to handle image loading
  const handleImageLoad = useCallback((index) => {
    setLoading((prevLoading) => {
      const updatedLoading = [...prevLoading];
      updatedLoading[index] = false;
      return updatedLoading;
    });
  }, []);
  const getImageUrl = (item, index) => {
    // Check if a URL already exists for this image, otherwise create one
    if (!imageUrlsRef.current[index]) {
      const url = URL.createObjectURL(item?.file);
      imageUrlsRef.current[index] = url;
    }
    return imageUrlsRef.current[index];
  };

  const handleImageChange = async (event) => {
    const { files } = event.target;
    console.log("files", files);
    if (files && files?.length) {
      setLoadingImage(files.length)
      const fileArray = Array.from(files);
      console.log("fileArray", fileArray);

      let fileImages = await Promise.all(fileArray.map((element) => processImage(element)));
      const processedImages = [];
      const uploadPromises = [];
      console.log("fileImages", fileImages);
      for (let i = 0; i < fileImages.length; i++) {
        if (!IMAGE_FILE_TYPES.includes(fileImages[i].file.type)) {
          fileImages[i].localError = true;
          fileImages[i].localErrorMessage = MESSAGES.VALID_IMAGE_FILE_TYPE;
        } else if (!fileImages[i].is4by3) {
          fileImages[i].localError = true;
          fileImages[i].localErrorMessage = MESSAGES.FILE_UPLOAD_ASPECT_RATIO;
        } else if (fileImages[i].file.size > MAXIMUM_FILE_SIZE_LIMIT) {
          fileImages[i].localError = true;
          fileImages[i].localErrorMessage = MAXIMUM_FILE_SIZE_LIMIT;
        }
        console.log(fileImages[i], "image")
        if (!fileImages[i]?.localError) {
          if (props?.formik?.initialStatus === "add") {
            const formData = new FormData();
            formData.append("file", fileImages[i]?.file);
            formData.append("type", FILE_UPLOAD_TYPE.PROPERTY);
            const res = await uploadImage(formData);
            uploadPromises.push(res?.data);
          } else {
            uploadPromises.push(fileImages[i]);
          }
        }
        processedImages.push(fileImages[i]);
      }

      const totalImages = (images?.length || 0) + fileImages.length;
      if (totalImages < MINIMUM_FILE_UPLOAD_LIMIT) {
        setError(true);
        setErrorMessage(MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
      } else if (totalImages > MAXIMUM_FILE_UPLOAD_LIMIT) {
        setError(true);
        setErrorMessage(MESSAGES.MAXIMUM_UPLOAD_FILE_LIMIT);
      } else {
        setError(false);
        setErrorMessage('');
      }

      setLoading((prevLoading) => [...prevLoading, ...processedImages.map(() => true)]);
      let selectedFiles = []
      if (props?.formik?.initialStatus === "add") {
        selectedFiles = uploadPromises
      } else {
        selectedFiles = uploadPromises?.map(item => item.file);
      }
      let selectedImageCopy = selectedImage ? selectedImage : [];
      let imagesCopy = images ? images : [];
      console.log("loading---loading", loading)
      dispatch({ type: COMMON_ACTIONS.SET_LOCAL_IMAGES, key: "selectedLocalmage", value: loading });

      dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "selectedImage", value: [...selectedImageCopy, ...processedImages] });
      dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: [...imagesCopy, ...selectedFiles] });
    }
  };

  const removeSpecificImage = (index) => {
    setLoadingImage(loadingImage - 1);

    console.log("index", index, selectedImage[index], images[index], "loading", loading);

    // Check and revoke object URL if exists
    if (imageUrlsRef.current[index]) {
      URL.revokeObjectURL(imageUrlsRef.current[index]);
      delete imageUrlsRef.current[index];
    }
    if (selectedImage[index]) {
      const updatedSelectedImage = selectedImage.filter((_, i) => i !== index);
      console.log("updatedSelectedImage", updatedSelectedImage);
      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "selectedImage",
        value: [...updatedSelectedImage], // Spread the array to ensure a new reference
      });
    }
    if (images[index]) {
      const updatedImages = images.filter((_, i) => i !== index);
      console.log("updatedImages", updatedImages);
      // setLoading((prevLoading) => [...prevLoading, ...updatedImages.map(() => true)]);
      dispatch({
        type: COMMON_ACTIONS.SET_IMAGES,
        key: "images",
        value: [...updatedImages], // Spread the array to ensure a new reference
      });
    }
    const loadinfilter = loading.filter((_, i) => i !== index)
    console.log("loadinfilter", loadinfilter)
    setLoading(loadinfilter);
    dispatch({ type: COMMON_ACTIONS.SET_LOCAL_IMAGES, key: "selectedLocalmage", value: loadinfilter });
    // setForceRender(forceRender + 1)
    // console.log("updatedSelectedImage", tempSelectedImage);

  };

  const validateImages = (tempImages) => {
    const invalidFileSize = tempImages.filter((item) => item.size && item.size > MAXIMUM_FILE_SIZE_LIMIT);
    const invalidFileTypes = tempImages.filter((item) => item.type && !IMAGE_FILE_TYPES.includes(item.type));

    if (tempImages.length < MINIMUM_FILE_UPLOAD_LIMIT) {
      props.formik.setFieldError("images", MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
    } else if (invalidFileSize.length) {
      props.formik.setFieldError("images", "Please upload a maximum file size of 3MB.");
    } else if (invalidFileTypes.length) {
      props.formik.setFieldError("images", MESSAGES.VALID_IMAGE_FILE_TYPE);
    } else {
      props.formik.setFieldError("images", "");
    }
  };

  const removeAllImages = () => {
    setLoadingImage(0)
    dispatch({
      type: COMMON_ACTIONS.SET_IMAGES,
      key: "selectedImage",
      value: [],
    });
    dispatch({ type: COMMON_ACTIONS.SET_IMAGES, key: "images", value: [] });
    props.formik.setFieldError("images", MESSAGES.MINIMUM_UPLOAD_FILE_LIMIT);
    Object.values(imageUrlsRef.current).forEach((url) => URL.revokeObjectURL(url));
    imageUrlsRef.current = {};
  };
  return (
    <Fragment>
      <label className="mb-2">Add Image</label>
      <div className="drop-image-outer" style={styles.container}>
        <Field
          name="images"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="show-for-sr"
          multiple
        />
        <span>
          <i className="fas fa-images"></i>
        </span>
      </div>

      {selectedImage?.length > 0 && (
        <div className={dropImageStyles.removeAll}>
          <button onClick={removeAllImages} style={styles.delete}>
            <p className={dropImageStyles["remove-images"]}>Remove All</p>
          </button>
        </div>
      )}

      <div className={`${dropImageStyles["image-pre-outer"]} ${(selectedImage?.length || loadingImage > 0) ? dropImageStyles["selected-images"] : ''}`}>
        {(selectedImage?.length) ? selectedImage?.map((item, index) => (
          <div key={index} className={dropImageStyles["main-img-priew"]} draggable style={styles.preview}>
            {loading[index] && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {/* <img
              name="hero"
              src={typeof item === "string" ? item : URL.createObjectURL(item?.file)}
              style={styles.image}
              alt="Thumb"
              onLoad={() => handleImageLoad(index)}
              hidden={loading[index]}
            /> */}
            <img
              name="hero"
              src={typeof item === "string" ? item : getImageUrl(item, index)}
              style={styles.image}
              alt="Thumb"
              onLoad={() => handleImageLoad(index)}
              hidden={loading[index]}
            />
            {item?.localError && item?.localErrorMessage && (
              <div className="error mt-2">{item.localErrorMessage}</div>
            )}
            <p onClick={() => removeSpecificImage(index)}>X</p>
          </div>
        )) : Array.from({ length: loadingImage })?.map((item, index) => (
          <div key={index} className={dropImageStyles["main-img-priew"]} draggable style={styles.preview}>
            {/* {loading[index] && ( */}
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            {/* )} */}

          </div>
        ))}

      </div>

      {error && errorMessage && <div className="error mt-2">{errorMessage}</div>}
    </Fragment >
  );
};

export default AppPropDropImage;